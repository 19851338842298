import { default as attributionsu5WEia7y4mMeta } from "/usr/src/app/pages/attributions.vue?macro=true";
import { default as errorTj02pANNGpMeta } from "/usr/src/app/pages/auth/error.vue?macro=true";
import { default as logink8UcAHPYydMeta } from "/usr/src/app/pages/auth/login.vue?macro=true";
import { default as indexFUlVCqSJ3PMeta } from "/usr/src/app/pages/auth/logout/index.vue?macro=true";
import { default as successuocuSvqcg2Meta } from "/usr/src/app/pages/auth/logout/success.vue?macro=true";
import { default as adtribute_45successtNUSQ87Q0dMeta } from "/usr/src/app/pages/connect/adtribute-success.vue?macro=true";
import { default as apikeyz1Rst3OqUGMeta } from "/usr/src/app/pages/connect/apikey.vue?macro=true";
import { default as errorwxds7D5xPxMeta } from "/usr/src/app/pages/connect/error.vue?macro=true";
import { default as googlesheets_45successchQtzHXKr6Meta } from "/usr/src/app/pages/connect/googlesheets-success.vue?macro=true";
import { default as load_45accountng2EtugPrRMeta } from "/usr/src/app/pages/connect/load-account.vue?macro=true";
import { default as load_45accountsRrs2Ped51FMeta } from "/usr/src/app/pages/connect/load-accounts.vue?macro=true";
import { default as load_45datadaq01HoDSpMeta } from "/usr/src/app/pages/connect/load-data.vue?macro=true";
import { default as AccountListLoadDatayreezHP78KMeta } from "/usr/src/app/pages/connect/partials/AccountListLoadData.vue?macro=true";
import { default as AccountListSelectAndAssignlacPsZafioMeta } from "/usr/src/app/pages/connect/partials/AccountListSelectAndAssign.vue?macro=true";
import { default as AccountLoaderX4GHVthV2BMeta } from "/usr/src/app/pages/connect/partials/AccountLoader.vue?macro=true";
import { default as BaseAccountListUHy3TjmLXvMeta } from "/usr/src/app/pages/connect/partials/BaseAccountList.vue?macro=true";
import { default as StepperKFfwDMqvJbMeta } from "/usr/src/app/pages/connect/partials/Stepper.vue?macro=true";
import { default as reauthenticate_45successejgdSV9pTwMeta } from "/usr/src/app/pages/connect/reauthenticate-success.vue?macro=true";
import { default as select_45accountsPbLwkJWzquMeta } from "/usr/src/app/pages/connect/select-accounts.vue?macro=true";
import { default as slack_45successJa6mnHgkgUMeta } from "/usr/src/app/pages/connect/slack-success.vue?macro=true";
import { default as tracify_45successe8QCB9HjH3Meta } from "/usr/src/app/pages/connect/tracify-success.vue?macro=true";
import { default as triplewhale_45successyr2OV36Z6JMeta } from "/usr/src/app/pages/connect/triplewhale-success.vue?macro=true";
import { default as _91uuid_932QqyOA6hyPMeta } from "/usr/src/app/pages/creative-comparison/detail/[uuid].vue?macro=true";
import { default as indexiaN9w7601RMeta } from "/usr/src/app/pages/creative-comparison/index.vue?macro=true";
import { default as CreateComparisonGroupsoq6ujGt8SQMeta } from "/usr/src/app/pages/creative-comparison/partials/CreateComparisonGroups.vue?macro=true";
import { default as CreativeComparisonReport71vfPl3dt9Meta } from "/usr/src/app/pages/creative-comparison/partials/CreativeComparisonReport.vue?macro=true";
import { default as UpsertComparisonCategoryModalEudlI4pGP9Meta } from "/usr/src/app/pages/creative-comparison/partials/UpsertComparisonCategoryModal.vue?macro=true";
import { default as _91uuid_93BsnI4JreKRMeta } from "/usr/src/app/pages/creative-comparison/share/[uuid].vue?macro=true";
import { default as _91uuid_93yqQwaggPWKMeta } from "/usr/src/app/pages/creative-comparison/snapshot/[uuid].vue?macro=true";
import { default as _91providerId_93yKKOAClRc1Meta } from "/usr/src/app/pages/creative-library/share/[providerId].vue?macro=true";
import { default as _91uuid_93fmfKAJpm88Meta } from "/usr/src/app/pages/creative-reporting/detail/[uuid].vue?macro=true";
import { default as indexHQTVWfuR41Meta } from "/usr/src/app/pages/creative-reporting/index.vue?macro=true";
import { default as CreativeReportingReportiIN9TVbQNLMeta } from "/usr/src/app/pages/creative-reporting/partials/CreativeReportingReport.vue?macro=true";
import { default as _91uuid_93SdHjLQg9ONMeta } from "/usr/src/app/pages/creative-reporting/share/[uuid].vue?macro=true";
import { default as _91uuid_93kT3WsDMFKUMeta } from "/usr/src/app/pages/creative-reporting/snapshot/[uuid].vue?macro=true";
import { default as indexT7LMaSAFAPMeta } from "/usr/src/app/pages/home/index.vue?macro=true";
import { default as OverviewCardEZ12vSfvvZMeta } from "/usr/src/app/pages/home/partials/OverviewCard.vue?macro=true";
import { default as indexgw2ikJsyhcMeta } from "/usr/src/app/pages/index.vue?macro=true";
import { default as _91uuid_93E08roErtXFMeta } from "/usr/src/app/pages/inspiration-board/detail/[uuid].vue?macro=true";
import { default as indexy7Rxny6rLlMeta } from "/usr/src/app/pages/inspiration-board/index.vue?macro=true";
import { default as DuplicateBoardModalXLUnWW1cI2Meta } from "/usr/src/app/pages/inspiration-board/partials/DuplicateBoardModal.vue?macro=true";
import { default as InspirationBoardNu5PNYTGvhMeta } from "/usr/src/app/pages/inspiration-board/partials/InspirationBoard.vue?macro=true";
import { default as InspirationBoardAssetGridwNhfCgwXq5Meta } from "/usr/src/app/pages/inspiration-board/partials/InspirationBoardAssetGrid.vue?macro=true";
import { default as InspirationBoardHeaderVMTaZeZDDxMeta } from "/usr/src/app/pages/inspiration-board/partials/InspirationBoardHeader.vue?macro=true";
import { default as UploadAssetModalp1D5LVhsGwMeta } from "/usr/src/app/pages/inspiration-board/partials/UploadAssetModal.vue?macro=true";
import { default as _91uuid_93MGV2uf2R3FMeta } from "/usr/src/app/pages/inspiration-board/share/asset/[uuid].vue?macro=true";
import { default as _91uuid_93csO4IpxyLZMeta } from "/usr/src/app/pages/inspiration-board/share/board/[uuid].vue?macro=true";
import { default as indexvpb1g54DMfMeta } from "/usr/src/app/pages/launch/cockpit/index.vue?macro=true";
import { default as _91uuid_93XISzDikR8pMeta } from "/usr/src/app/pages/launch/detail/[uuid].vue?macro=true";
import { default as index1Lamz1IDpaMeta } from "/usr/src/app/pages/launch/index.vue?macro=true";
import { default as CreativeStrategistCompletions9EewubYDGrMeta } from "/usr/src/app/pages/launch/partials/CreativeStrategistCompletions.vue?macro=true";
import { default as CreativeStrategistModaljjhOJuEiutMeta } from "/usr/src/app/pages/launch/partials/CreativeStrategistModal.vue?macro=true";
import { default as DuplicateLaunchReportModaln0o9fBoOP7Meta } from "/usr/src/app/pages/launch/partials/DuplicateLaunchReportModal.vue?macro=true";
import { default as EditSubViewFilterModalwYS1Hkna9WMeta } from "/usr/src/app/pages/launch/partials/EditSubViewFilterModal.vue?macro=true";
import { default as LaunchReportAqLHTWFyWyMeta } from "/usr/src/app/pages/launch/partials/LaunchReport.vue?macro=true";
import { default as LaunchReportHeaderHcoBSma7I1Meta } from "/usr/src/app/pages/launch/partials/LaunchReportHeader.vue?macro=true";
import { default as LaunchReportTabsw1CdcJgGzhMeta } from "/usr/src/app/pages/launch/partials/LaunchReportTabs.vue?macro=true";
import { default as LaunchReportToolbarmExQcskOakMeta } from "/usr/src/app/pages/launch/partials/LaunchReportToolbar.vue?macro=true";
import { default as _91uuid_93B2LPwwKLyGMeta } from "/usr/src/app/pages/launch/share/[uuid].vue?macro=true";
import { default as _91uuid_93tJ6Od3vtKJMeta } from "/usr/src/app/pages/launch/snapshot/[uuid].vue?macro=true";
import { default as accept_45agbA3fp96MvB9Meta } from "/usr/src/app/pages/legal/accept-agb.vue?macro=true";
import { default as notificationsOGaQfoEYYkMeta } from "/usr/src/app/pages/notifications.vue?macro=true";
import { default as create_45connectioniTVgaBpvA8Meta } from "/usr/src/app/pages/onboarding/create-connection.vue?macro=true";
import { default as select_45clienteODWz8RrTSMeta } from "/usr/src/app/pages/onboarding/select-client.vue?macro=true";
import { default as change_45billing_45info_45successsyIKZT9njwMeta } from "/usr/src/app/pages/payment/change-billing-info-success.vue?macro=true";
import { default as change_45billing_45infohbUQAhjbklMeta } from "/usr/src/app/pages/payment/change-billing-info.vue?macro=true";
import { default as change_45payment_45methodA4mFhO3xhTMeta } from "/usr/src/app/pages/payment/change-payment-method.vue?macro=true";
import { default as onboarding_45bookedz2hFlrsGKtMeta } from "/usr/src/app/pages/payment/onboarding-booked.vue?macro=true";
import { default as onboarding_45sessionG1rv9q8nw7Meta } from "/usr/src/app/pages/payment/onboarding-session.vue?macro=true";
import { default as AddressForm1YOV6J3vGZMeta } from "/usr/src/app/pages/payment/partials/AddressForm.vue?macro=true";
import { default as PaymentDetailsBookPlanI3eQFxcCTOMeta } from "/usr/src/app/pages/payment/partials/PaymentDetailsBookPlan.vue?macro=true";
import { default as PaymentDetailsChangePlan7CPnbkx46SMeta } from "/usr/src/app/pages/payment/partials/PaymentDetailsChangePlan.vue?macro=true";
import { default as PlanInfoAlertiqkDVPGRyaMeta } from "/usr/src/app/pages/payment/partials/PlanInfoAlert.vue?macro=true";
import { default as PricingIntervalTogglemrEkQV5nrcMeta } from "/usr/src/app/pages/payment/partials/PricingIntervalToggle.vue?macro=true";
import { default as PromoCode9f6rSTXJo8Meta } from "/usr/src/app/pages/payment/partials/PromoCode.vue?macro=true";
import { default as WaitingForBackendxszxCaRQFAMeta } from "/usr/src/app/pages/payment/partials/WaitingForBackend.vue?macro=true";
import { default as WaitingForConfirmationFuJ7HarwHvMeta } from "/usr/src/app/pages/payment/partials/WaitingForConfirmation.vue?macro=true";
import { default as payment_45completedFfliZbh0nDMeta } from "/usr/src/app/pages/payment/payment-completed.vue?macro=true";
import { default as payment_45details_45update_45completed7tSDQIdW0IMeta } from "/usr/src/app/pages/payment/payment-details-update-completed.vue?macro=true";
import { default as payment_45detailsUmvGugO3gAMeta } from "/usr/src/app/pages/payment/payment-details.vue?macro=true";
import { default as select_45planNcdR8G00MbMeta } from "/usr/src/app/pages/payment/select-plan.vue?macro=true";
import { default as subscription_45expiredX3n0pVmNibMeta } from "/usr/src/app/pages/payment/subscription-expired.vue?macro=true";
import { default as upgrade_45completed3SWMpocVCZMeta } from "/usr/src/app/pages/payment/upgrade-completed.vue?macro=true";
import { default as billingyoICfB7F6KMeta } from "/usr/src/app/pages/settings/billing.vue?macro=true";
import { default as clients8dQpKN4Pm8Meta } from "/usr/src/app/pages/settings/clients.vue?macro=true";
import { default as connectionsoUe0WByJQCMeta } from "/usr/src/app/pages/settings/connections.vue?macro=true";
import { default as legalEDfJtX3qc9Meta } from "/usr/src/app/pages/settings/legal.vue?macro=true";
import { default as AccountListw5nNYU1IoCMeta } from "/usr/src/app/pages/settings/partials/AccountList.vue?macro=true";
import { default as AppUserCreateSlideoverNjGWMxNZ2CMeta } from "/usr/src/app/pages/settings/partials/AppUserCreateSlideover.vue?macro=true";
import { default as AppUserDetailSlideover7SOIHyHUkQMeta } from "/usr/src/app/pages/settings/partials/AppUserDetailSlideover.vue?macro=true";
import { default as AppUserEditSlideoverFl2pB7sImfMeta } from "/usr/src/app/pages/settings/partials/AppUserEditSlideover.vue?macro=true";
import { default as AppUserList27gU2DuKzBMeta } from "/usr/src/app/pages/settings/partials/AppUserList.vue?macro=true";
import { default as AppUserRoleSelectu5uaSr3BblMeta } from "/usr/src/app/pages/settings/partials/AppUserRoleSelect.vue?macro=true";
import { default as AppUserRoleTag6JMEcqAeZvMeta } from "/usr/src/app/pages/settings/partials/AppUserRoleTag.vue?macro=true";
import { default as BillingYearSelect0IZP9oMyyAMeta } from "/usr/src/app/pages/settings/partials/BillingYearSelect.vue?macro=true";
import { default as ClientAccountsDraggable8gvGh3HgyGMeta } from "/usr/src/app/pages/settings/partials/ClientAccountsDraggable.vue?macro=true";
import { default as ClientAccountsWrapperpv1PSXmWiYMeta } from "/usr/src/app/pages/settings/partials/ClientAccountsWrapper.vue?macro=true";
import { default as ClientCreateSlideover8hogu91QsPMeta } from "/usr/src/app/pages/settings/partials/ClientCreateSlideover.vue?macro=true";
import { default as ClientDetailPlatformTabsrcTDCu37sRMeta } from "/usr/src/app/pages/settings/partials/ClientDetailPlatformTabs.vue?macro=true";
import { default as ClientDetailSlideoverrDOhyDfOxSMeta } from "/usr/src/app/pages/settings/partials/ClientDetailSlideover.vue?macro=true";
import { default as ClientEditSlideovermFJQW1spxuMeta } from "/usr/src/app/pages/settings/partials/ClientEditSlideover.vue?macro=true";
import { default as ClientFormS94nnNGYDtMeta } from "/usr/src/app/pages/settings/partials/ClientForm.vue?macro=true";
import { default as ClientLayoutSelectY2DLjKds75Meta } from "/usr/src/app/pages/settings/partials/ClientLayoutSelect.vue?macro=true";
import { default as ClientListrXB505RlnNMeta } from "/usr/src/app/pages/settings/partials/ClientList.vue?macro=true";
import { default as ConnectionErrorAlertsn03GjyddYMeta } from "/usr/src/app/pages/settings/partials/ConnectionErrorAlert.vue?macro=true";
import { default as ConnectionReauthenticationAlerttCAPkRnBpBMeta } from "/usr/src/app/pages/settings/partials/ConnectionReauthenticationAlert.vue?macro=true";
import { default as InvoicesListnzqHS08LTiMeta } from "/usr/src/app/pages/settings/partials/InvoicesList.vue?macro=true";
import { default as NoClientsPlaceholderYj73Zg0BjMMeta } from "/usr/src/app/pages/settings/partials/NoClientsPlaceholder.vue?macro=true";
import { default as NotificationTogglesWnsNX70hiMeta } from "/usr/src/app/pages/settings/partials/NotificationToggle.vue?macro=true";
import { default as PaymentMethodIconJc6DN5PIiaMeta } from "/usr/src/app/pages/settings/partials/PaymentMethodIcon.vue?macro=true";
import { default as SelectAccountsModal0NkabeEdCuMeta } from "/usr/src/app/pages/settings/partials/SelectAccountsModal.vue?macro=true";
import { default as SettingsLayoutNaK66NvG3kMeta } from "/usr/src/app/pages/settings/partials/SettingsLayout.vue?macro=true";
import { default as SettingsSectionAiG6ujMQgyMeta } from "/usr/src/app/pages/settings/partials/SettingsSection.vue?macro=true";
import { default as TopCreativeDigestFormIpXkPwbkBvMeta } from "/usr/src/app/pages/settings/partials/TopCreativeDigestForm.vue?macro=true";
import { default as UnassignedAccountsWrapperHP14NhaZsAMeta } from "/usr/src/app/pages/settings/partials/UnassignedAccountsWrapper.vue?macro=true";
import { default as profile1vIyvgeGNGMeta } from "/usr/src/app/pages/settings/profile.vue?macro=true";
import { default as teamhUlV9yjnJwMeta } from "/usr/src/app/pages/settings/team.vue?macro=true";
import { default as workspaceu3QezfgBQTMeta } from "/usr/src/app/pages/settings/workspace.vue?macro=true";
import { default as indexZh0bVlLDf6Meta } from "/usr/src/app/pages/signup/index.vue?macro=true";
import { default as SignUpFormrvDxIhlLtqMeta } from "/usr/src/app/pages/signup/partials/SignUpForm.vue?macro=true";
import { default as labeledROYL86MZ8sMeta } from "/usr/src/app/pages/tagging/labeled.vue?macro=true";
import { default as BulkActions43x77vrRvfMeta } from "/usr/src/app/pages/tagging/partials/BulkActions.vue?macro=true";
import { default as NoTagsPlaceholdervWSFsrbo6MMeta } from "/usr/src/app/pages/tagging/partials/NoTagsPlaceholder.vue?macro=true";
import { default as SelectTagsErX5OyQcRIMeta } from "/usr/src/app/pages/tagging/partials/SelectTags.vue?macro=true";
import { default as SelectTagsModalQSzjHheLkZMeta } from "/usr/src/app/pages/tagging/partials/SelectTagsModal.vue?macro=true";
import { default as TaggingLayoutJ37WSsRpj5Meta } from "/usr/src/app/pages/tagging/partials/TaggingLayout.vue?macro=true";
import { default as TagListC0gAiAc8ZDMeta } from "/usr/src/app/pages/tagging/partials/TagList.vue?macro=true";
import { default as TagSingleAdGxKky8uV1jMeta } from "/usr/src/app/pages/tagging/partials/TagSingleAd.vue?macro=true";
import { default as ToggleUnlabeledYf00l4mPVDMeta } from "/usr/src/app/pages/tagging/partials/ToggleUnlabeled.vue?macro=true";
import { default as unlabeledIdau8Hx5VyMeta } from "/usr/src/app/pages/tagging/unlabeled.vue?macro=true";
import { default as indexBqPEVVpQYaMeta } from "/usr/src/app/pages/testing-log/cockpit/index.vue?macro=true";
import { default as _91uuid_93V334E3B67WMeta } from "/usr/src/app/pages/testing-log/detail/[uuid].vue?macro=true";
import { default as indextuLQUcl61ZMeta } from "/usr/src/app/pages/testing-log/index.vue?macro=true";
import { default as AnalyzingNamingConventionPlaceholderck2NtteW16Meta } from "/usr/src/app/pages/testing-log/partials/AnalyzingNamingConventionPlaceholder.vue?macro=true";
import { default as BreadcrumbsCBiUFC1klYMeta } from "/usr/src/app/pages/testing-log/partials/Breadcrumbs.vue?macro=true";
import { default as CopyNamingConventionbNV9uOmFgYMeta } from "/usr/src/app/pages/testing-log/partials/CopyNamingConvention.vue?macro=true";
import { default as DuplicateTestingLogReportModalFDb9ChU4P8Meta } from "/usr/src/app/pages/testing-log/partials/DuplicateTestingLogReportModal.vue?macro=true";
import { default as NamingConventionBuilderSY4jJBkT7TMeta } from "/usr/src/app/pages/testing-log/partials/NamingConventionBuilder.vue?macro=true";
import { default as QuestionxBOFYMfLRHMeta } from "/usr/src/app/pages/testing-log/partials/Question.vue?macro=true";
import { default as TestingLogN0hHQXmP0VMeta } from "/usr/src/app/pages/testing-log/partials/TestingLog.vue?macro=true";
import { default as TestingLogAskForNamingConventionbqYzXnGrcrMeta } from "/usr/src/app/pages/testing-log/partials/TestingLogAskForNamingConvention.vue?macro=true";
import { default as TestingLogCockpitHeadererume4KNpdMeta } from "/usr/src/app/pages/testing-log/partials/TestingLogCockpitHeader.vue?macro=true";
import { default as TestingLogColumnCardcTa21g6efqMeta } from "/usr/src/app/pages/testing-log/partials/TestingLogColumnCard.vue?macro=true";
import { default as TestingLogColumnGrid3s177MXO3fMeta } from "/usr/src/app/pages/testing-log/partials/TestingLogColumnGrid.vue?macro=true";
import { default as TestingLogGroupDetailSlideoverlfy5ToMzAYMeta } from "/usr/src/app/pages/testing-log/partials/TestingLogGroupDetailSlideover.vue?macro=true";
import { default as TestingLogHeaderMiMRumjKS3Meta } from "/usr/src/app/pages/testing-log/partials/TestingLogHeader.vue?macro=true";
import { default as TestingLogPrimaryColumnSelect5aJCPss0n4Meta } from "/usr/src/app/pages/testing-log/partials/TestingLogPrimaryColumnSelect.vue?macro=true";
import { default as TestingLogReport5csPSR6jnJMeta } from "/usr/src/app/pages/testing-log/partials/TestingLogReport.vue?macro=true";
import { default as TestingLogSecondaryColumnsSelectdvxQMSylg4Meta } from "/usr/src/app/pages/testing-log/partials/TestingLogSecondaryColumnsSelect.vue?macro=true";
import { default as TestingLogTable3Mc32oAbNYMeta } from "/usr/src/app/pages/testing-log/partials/TestingLogTable.vue?macro=true";
import { default as TestingLogTabsAdU4L2McAoMeta } from "/usr/src/app/pages/testing-log/partials/TestingLogTabs.vue?macro=true";
import { default as TestingLogToolbarlo8JRhUHPLMeta } from "/usr/src/app/pages/testing-log/partials/TestingLogToolbar.vue?macro=true";
import { default as TestingLogTutorialModalQa4OxJSOueMeta } from "/usr/src/app/pages/testing-log/partials/TestingLogTutorialModal.vue?macro=true";
import { default as TestingLogUpsertCategorySlideover59wMh2R84SMeta } from "/usr/src/app/pages/testing-log/partials/TestingLogUpsertCategorySlideover.vue?macro=true";
import { default as UpsertNamingConventionCategoryModalp90IYIo2hsMeta } from "/usr/src/app/pages/testing-log/partials/UpsertNamingConventionCategoryModal.vue?macro=true";
import { default as ai_45naming_45conventionGoTbqom2gTMeta } from "/usr/src/app/pages/testing-log/setup/ai-naming-convention.vue?macro=true";
import { default as ask_45automatic_45setupekvVtpboNnMeta } from "/usr/src/app/pages/testing-log/setup/ask-automatic-setup.vue?macro=true";
import { default as naming_45convention_45builderORdFik8lL9Meta } from "/usr/src/app/pages/testing-log/setup/naming-convention-builder.vue?macro=true";
import { default as startfE82MHVYosMeta } from "/usr/src/app/pages/testing-log/setup/start.vue?macro=true";
import { default as tagging9S2ZmTqQbRMeta } from "/usr/src/app/pages/testing-log/setup/tagging.vue?macro=true";
import { default as tutoriald4uALT37UGMeta } from "/usr/src/app/pages/testing-log/setup/tutorial.vue?macro=true";
import { default as _91uuid_93nqPTQaj4owMeta } from "/usr/src/app/pages/testing-log/share/[uuid].vue?macro=true";
import { default as _91uuid_93YQgVPeBVskMeta } from "/usr/src/app/pages/testing-log/snapshot/[uuid].vue?macro=true";
export default [
  {
    name: "attributions",
    path: "/attributions",
    component: () => import("/usr/src/app/pages/attributions.vue").then(m => m.default || m)
  },
  {
    name: "auth-error",
    path: "/auth/error",
    meta: errorTj02pANNGpMeta || {},
    component: () => import("/usr/src/app/pages/auth/error.vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: logink8UcAHPYydMeta || {},
    component: () => import("/usr/src/app/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-logout",
    path: "/auth/logout",
    meta: indexFUlVCqSJ3PMeta || {},
    component: () => import("/usr/src/app/pages/auth/logout/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-logout-success",
    path: "/auth/logout/success",
    meta: successuocuSvqcg2Meta || {},
    component: () => import("/usr/src/app/pages/auth/logout/success.vue").then(m => m.default || m)
  },
  {
    name: "connect-adtribute-success",
    path: "/connect/adtribute-success",
    component: () => import("/usr/src/app/pages/connect/adtribute-success.vue").then(m => m.default || m)
  },
  {
    name: "connect-apikey",
    path: "/connect/apikey",
    component: () => import("/usr/src/app/pages/connect/apikey.vue").then(m => m.default || m)
  },
  {
    name: "connect-error",
    path: "/connect/error",
    component: () => import("/usr/src/app/pages/connect/error.vue").then(m => m.default || m)
  },
  {
    name: "connect-googlesheets-success",
    path: "/connect/googlesheets-success",
    component: () => import("/usr/src/app/pages/connect/googlesheets-success.vue").then(m => m.default || m)
  },
  {
    name: "connect-load-account",
    path: "/connect/load-account",
    component: () => import("/usr/src/app/pages/connect/load-account.vue").then(m => m.default || m)
  },
  {
    name: "connect-load-accounts",
    path: "/connect/load-accounts",
    component: () => import("/usr/src/app/pages/connect/load-accounts.vue").then(m => m.default || m)
  },
  {
    name: "connect-load-data",
    path: "/connect/load-data",
    component: () => import("/usr/src/app/pages/connect/load-data.vue").then(m => m.default || m)
  },
  {
    name: "connect-partials-AccountListLoadData",
    path: "/connect/partials/AccountListLoadData",
    component: () => import("/usr/src/app/pages/connect/partials/AccountListLoadData.vue").then(m => m.default || m)
  },
  {
    name: "connect-partials-AccountListSelectAndAssign",
    path: "/connect/partials/AccountListSelectAndAssign",
    component: () => import("/usr/src/app/pages/connect/partials/AccountListSelectAndAssign.vue").then(m => m.default || m)
  },
  {
    name: "connect-partials-AccountLoader",
    path: "/connect/partials/AccountLoader",
    component: () => import("/usr/src/app/pages/connect/partials/AccountLoader.vue").then(m => m.default || m)
  },
  {
    name: "connect-partials-BaseAccountList",
    path: "/connect/partials/BaseAccountList",
    component: () => import("/usr/src/app/pages/connect/partials/BaseAccountList.vue").then(m => m.default || m)
  },
  {
    name: "connect-partials-Stepper",
    path: "/connect/partials/Stepper",
    component: () => import("/usr/src/app/pages/connect/partials/Stepper.vue").then(m => m.default || m)
  },
  {
    name: "connect-reauthenticate-success",
    path: "/connect/reauthenticate-success",
    component: () => import("/usr/src/app/pages/connect/reauthenticate-success.vue").then(m => m.default || m)
  },
  {
    name: "connect-select-accounts",
    path: "/connect/select-accounts",
    component: () => import("/usr/src/app/pages/connect/select-accounts.vue").then(m => m.default || m)
  },
  {
    name: "connect-slack-success",
    path: "/connect/slack-success",
    component: () => import("/usr/src/app/pages/connect/slack-success.vue").then(m => m.default || m)
  },
  {
    name: "connect-tracify-success",
    path: "/connect/tracify-success",
    component: () => import("/usr/src/app/pages/connect/tracify-success.vue").then(m => m.default || m)
  },
  {
    name: "connect-triplewhale-success",
    path: "/connect/triplewhale-success",
    component: () => import("/usr/src/app/pages/connect/triplewhale-success.vue").then(m => m.default || m)
  },
  {
    name: "creative-comparison-detail-uuid",
    path: "/creative-comparison/detail/:uuid()",
    component: () => import("/usr/src/app/pages/creative-comparison/detail/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "creative-comparison",
    path: "/creative-comparison",
    meta: indexiaN9w7601RMeta || {},
    component: () => import("/usr/src/app/pages/creative-comparison/index.vue").then(m => m.default || m)
  },
  {
    name: "creative-comparison-partials-CreateComparisonGroups",
    path: "/creative-comparison/partials/CreateComparisonGroups",
    component: () => import("/usr/src/app/pages/creative-comparison/partials/CreateComparisonGroups.vue").then(m => m.default || m)
  },
  {
    name: "creative-comparison-partials-CreativeComparisonReport",
    path: "/creative-comparison/partials/CreativeComparisonReport",
    component: () => import("/usr/src/app/pages/creative-comparison/partials/CreativeComparisonReport.vue").then(m => m.default || m)
  },
  {
    name: "creative-comparison-partials-UpsertComparisonCategoryModal",
    path: "/creative-comparison/partials/UpsertComparisonCategoryModal",
    component: () => import("/usr/src/app/pages/creative-comparison/partials/UpsertComparisonCategoryModal.vue").then(m => m.default || m)
  },
  {
    name: "creative-comparison-share-uuid",
    path: "/creative-comparison/share/:uuid()",
    meta: _91uuid_93BsnI4JreKRMeta || {},
    component: () => import("/usr/src/app/pages/creative-comparison/share/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "creative-comparison-snapshot-uuid",
    path: "/creative-comparison/snapshot/:uuid()",
    meta: _91uuid_93yqQwaggPWKMeta || {},
    component: () => import("/usr/src/app/pages/creative-comparison/snapshot/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "creative-library-share-providerId",
    path: "/creative-library/share/:providerId()",
    meta: _91providerId_93yKKOAClRc1Meta || {},
    component: () => import("/usr/src/app/pages/creative-library/share/[providerId].vue").then(m => m.default || m)
  },
  {
    name: "creative-reporting-detail-uuid",
    path: "/creative-reporting/detail/:uuid()",
    component: () => import("/usr/src/app/pages/creative-reporting/detail/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "creative-reporting",
    path: "/creative-reporting",
    meta: indexHQTVWfuR41Meta || {},
    component: () => import("/usr/src/app/pages/creative-reporting/index.vue").then(m => m.default || m)
  },
  {
    name: "creative-reporting-partials-CreativeReportingReport",
    path: "/creative-reporting/partials/CreativeReportingReport",
    component: () => import("/usr/src/app/pages/creative-reporting/partials/CreativeReportingReport.vue").then(m => m.default || m)
  },
  {
    name: "creative-reporting-share-uuid",
    path: "/creative-reporting/share/:uuid()",
    meta: _91uuid_93SdHjLQg9ONMeta || {},
    component: () => import("/usr/src/app/pages/creative-reporting/share/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "creative-reporting-snapshot-uuid",
    path: "/creative-reporting/snapshot/:uuid()",
    meta: _91uuid_93kT3WsDMFKUMeta || {},
    component: () => import("/usr/src/app/pages/creative-reporting/snapshot/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "home",
    path: "/home",
    meta: indexT7LMaSAFAPMeta || {},
    component: () => import("/usr/src/app/pages/home/index.vue").then(m => m.default || m)
  },
  {
    name: "home-partials-OverviewCard",
    path: "/home/partials/OverviewCard",
    component: () => import("/usr/src/app/pages/home/partials/OverviewCard.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/usr/src/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "inspiration-board-detail-uuid",
    path: "/inspiration-board/detail/:uuid()",
    component: () => import("/usr/src/app/pages/inspiration-board/detail/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "inspiration-board",
    path: "/inspiration-board",
    meta: indexy7Rxny6rLlMeta || {},
    component: () => import("/usr/src/app/pages/inspiration-board/index.vue").then(m => m.default || m)
  },
  {
    name: "inspiration-board-partials-DuplicateBoardModal",
    path: "/inspiration-board/partials/DuplicateBoardModal",
    component: () => import("/usr/src/app/pages/inspiration-board/partials/DuplicateBoardModal.vue").then(m => m.default || m)
  },
  {
    name: "inspiration-board-partials-InspirationBoard",
    path: "/inspiration-board/partials/InspirationBoard",
    component: () => import("/usr/src/app/pages/inspiration-board/partials/InspirationBoard.vue").then(m => m.default || m)
  },
  {
    name: "inspiration-board-partials-InspirationBoardAssetGrid",
    path: "/inspiration-board/partials/InspirationBoardAssetGrid",
    component: () => import("/usr/src/app/pages/inspiration-board/partials/InspirationBoardAssetGrid.vue").then(m => m.default || m)
  },
  {
    name: "inspiration-board-partials-InspirationBoardHeader",
    path: "/inspiration-board/partials/InspirationBoardHeader",
    component: () => import("/usr/src/app/pages/inspiration-board/partials/InspirationBoardHeader.vue").then(m => m.default || m)
  },
  {
    name: "inspiration-board-partials-UploadAssetModal",
    path: "/inspiration-board/partials/UploadAssetModal",
    component: () => import("/usr/src/app/pages/inspiration-board/partials/UploadAssetModal.vue").then(m => m.default || m)
  },
  {
    name: "inspiration-board-share-asset-uuid",
    path: "/inspiration-board/share/asset/:uuid()",
    meta: _91uuid_93MGV2uf2R3FMeta || {},
    component: () => import("/usr/src/app/pages/inspiration-board/share/asset/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "inspiration-board-share-board-uuid",
    path: "/inspiration-board/share/board/:uuid()",
    meta: _91uuid_93csO4IpxyLZMeta || {},
    component: () => import("/usr/src/app/pages/inspiration-board/share/board/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "launch-cockpit",
    path: "/launch/cockpit",
    component: () => import("/usr/src/app/pages/launch/cockpit/index.vue").then(m => m.default || m)
  },
  {
    name: "launch-detail-uuid",
    path: "/launch/detail/:uuid()",
    component: () => import("/usr/src/app/pages/launch/detail/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "launch",
    path: "/launch",
    meta: index1Lamz1IDpaMeta || {},
    component: () => import("/usr/src/app/pages/launch/index.vue").then(m => m.default || m)
  },
  {
    name: "launch-partials-CreativeStrategistCompletions",
    path: "/launch/partials/CreativeStrategistCompletions",
    component: () => import("/usr/src/app/pages/launch/partials/CreativeStrategistCompletions.vue").then(m => m.default || m)
  },
  {
    name: "launch-partials-CreativeStrategistModal",
    path: "/launch/partials/CreativeStrategistModal",
    component: () => import("/usr/src/app/pages/launch/partials/CreativeStrategistModal.vue").then(m => m.default || m)
  },
  {
    name: "launch-partials-DuplicateLaunchReportModal",
    path: "/launch/partials/DuplicateLaunchReportModal",
    component: () => import("/usr/src/app/pages/launch/partials/DuplicateLaunchReportModal.vue").then(m => m.default || m)
  },
  {
    name: "launch-partials-EditSubViewFilterModal",
    path: "/launch/partials/EditSubViewFilterModal",
    component: () => import("/usr/src/app/pages/launch/partials/EditSubViewFilterModal.vue").then(m => m.default || m)
  },
  {
    name: "launch-partials-LaunchReport",
    path: "/launch/partials/LaunchReport",
    component: () => import("/usr/src/app/pages/launch/partials/LaunchReport.vue").then(m => m.default || m)
  },
  {
    name: "launch-partials-LaunchReportHeader",
    path: "/launch/partials/LaunchReportHeader",
    component: () => import("/usr/src/app/pages/launch/partials/LaunchReportHeader.vue").then(m => m.default || m)
  },
  {
    name: "launch-partials-LaunchReportTabs",
    path: "/launch/partials/LaunchReportTabs",
    component: () => import("/usr/src/app/pages/launch/partials/LaunchReportTabs.vue").then(m => m.default || m)
  },
  {
    name: "launch-partials-LaunchReportToolbar",
    path: "/launch/partials/LaunchReportToolbar",
    component: () => import("/usr/src/app/pages/launch/partials/LaunchReportToolbar.vue").then(m => m.default || m)
  },
  {
    name: "launch-share-uuid",
    path: "/launch/share/:uuid()",
    meta: _91uuid_93B2LPwwKLyGMeta || {},
    component: () => import("/usr/src/app/pages/launch/share/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "launch-snapshot-uuid",
    path: "/launch/snapshot/:uuid()",
    meta: _91uuid_93tJ6Od3vtKJMeta || {},
    component: () => import("/usr/src/app/pages/launch/snapshot/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "legal-accept-agb",
    path: "/legal/accept-agb",
    component: () => import("/usr/src/app/pages/legal/accept-agb.vue").then(m => m.default || m)
  },
  {
    name: "notifications",
    path: "/notifications",
    component: () => import("/usr/src/app/pages/notifications.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-create-connection",
    path: "/onboarding/create-connection",
    component: () => import("/usr/src/app/pages/onboarding/create-connection.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-select-client",
    path: "/onboarding/select-client",
    component: () => import("/usr/src/app/pages/onboarding/select-client.vue").then(m => m.default || m)
  },
  {
    name: "payment-change-billing-info-success",
    path: "/payment/change-billing-info-success",
    component: () => import("/usr/src/app/pages/payment/change-billing-info-success.vue").then(m => m.default || m)
  },
  {
    name: "payment-change-billing-info",
    path: "/payment/change-billing-info",
    component: () => import("/usr/src/app/pages/payment/change-billing-info.vue").then(m => m.default || m)
  },
  {
    name: "payment-change-payment-method",
    path: "/payment/change-payment-method",
    component: () => import("/usr/src/app/pages/payment/change-payment-method.vue").then(m => m.default || m)
  },
  {
    name: "payment-onboarding-booked",
    path: "/payment/onboarding-booked",
    component: () => import("/usr/src/app/pages/payment/onboarding-booked.vue").then(m => m.default || m)
  },
  {
    name: "payment-onboarding-session",
    path: "/payment/onboarding-session",
    component: () => import("/usr/src/app/pages/payment/onboarding-session.vue").then(m => m.default || m)
  },
  {
    name: "payment-partials-AddressForm",
    path: "/payment/partials/AddressForm",
    component: () => import("/usr/src/app/pages/payment/partials/AddressForm.vue").then(m => m.default || m)
  },
  {
    name: "payment-partials-PaymentDetailsBookPlan",
    path: "/payment/partials/PaymentDetailsBookPlan",
    component: () => import("/usr/src/app/pages/payment/partials/PaymentDetailsBookPlan.vue").then(m => m.default || m)
  },
  {
    name: "payment-partials-PaymentDetailsChangePlan",
    path: "/payment/partials/PaymentDetailsChangePlan",
    component: () => import("/usr/src/app/pages/payment/partials/PaymentDetailsChangePlan.vue").then(m => m.default || m)
  },
  {
    name: "payment-partials-PlanInfoAlert",
    path: "/payment/partials/PlanInfoAlert",
    component: () => import("/usr/src/app/pages/payment/partials/PlanInfoAlert.vue").then(m => m.default || m)
  },
  {
    name: "payment-partials-PricingIntervalToggle",
    path: "/payment/partials/PricingIntervalToggle",
    component: () => import("/usr/src/app/pages/payment/partials/PricingIntervalToggle.vue").then(m => m.default || m)
  },
  {
    name: "payment-partials-PromoCode",
    path: "/payment/partials/PromoCode",
    component: () => import("/usr/src/app/pages/payment/partials/PromoCode.vue").then(m => m.default || m)
  },
  {
    name: "payment-partials-WaitingForBackend",
    path: "/payment/partials/WaitingForBackend",
    component: () => import("/usr/src/app/pages/payment/partials/WaitingForBackend.vue").then(m => m.default || m)
  },
  {
    name: "payment-partials-WaitingForConfirmation",
    path: "/payment/partials/WaitingForConfirmation",
    component: () => import("/usr/src/app/pages/payment/partials/WaitingForConfirmation.vue").then(m => m.default || m)
  },
  {
    name: "payment-payment-completed",
    path: "/payment/payment-completed",
    component: () => import("/usr/src/app/pages/payment/payment-completed.vue").then(m => m.default || m)
  },
  {
    name: "payment-payment-details-update-completed",
    path: "/payment/payment-details-update-completed",
    component: () => import("/usr/src/app/pages/payment/payment-details-update-completed.vue").then(m => m.default || m)
  },
  {
    name: "payment-payment-details",
    path: "/payment/payment-details",
    component: () => import("/usr/src/app/pages/payment/payment-details.vue").then(m => m.default || m)
  },
  {
    name: "payment-select-plan",
    path: "/payment/select-plan",
    component: () => import("/usr/src/app/pages/payment/select-plan.vue").then(m => m.default || m)
  },
  {
    name: "payment-subscription-expired",
    path: "/payment/subscription-expired",
    component: () => import("/usr/src/app/pages/payment/subscription-expired.vue").then(m => m.default || m)
  },
  {
    name: "payment-upgrade-completed",
    path: "/payment/upgrade-completed",
    component: () => import("/usr/src/app/pages/payment/upgrade-completed.vue").then(m => m.default || m)
  },
  {
    name: "settings-billing",
    path: "/settings/billing",
    component: () => import("/usr/src/app/pages/settings/billing.vue").then(m => m.default || m)
  },
  {
    name: "settings-clients",
    path: "/settings/clients",
    component: () => import("/usr/src/app/pages/settings/clients.vue").then(m => m.default || m)
  },
  {
    name: "settings-connections",
    path: "/settings/connections",
    component: () => import("/usr/src/app/pages/settings/connections.vue").then(m => m.default || m)
  },
  {
    name: "settings-legal",
    path: "/settings/legal",
    component: () => import("/usr/src/app/pages/settings/legal.vue").then(m => m.default || m)
  },
  {
    name: "settings-partials-AccountList",
    path: "/settings/partials/AccountList",
    component: () => import("/usr/src/app/pages/settings/partials/AccountList.vue").then(m => m.default || m)
  },
  {
    name: "settings-partials-AppUserCreateSlideover",
    path: "/settings/partials/AppUserCreateSlideover",
    component: () => import("/usr/src/app/pages/settings/partials/AppUserCreateSlideover.vue").then(m => m.default || m)
  },
  {
    name: "settings-partials-AppUserDetailSlideover",
    path: "/settings/partials/AppUserDetailSlideover",
    component: () => import("/usr/src/app/pages/settings/partials/AppUserDetailSlideover.vue").then(m => m.default || m)
  },
  {
    name: "settings-partials-AppUserEditSlideover",
    path: "/settings/partials/AppUserEditSlideover",
    component: () => import("/usr/src/app/pages/settings/partials/AppUserEditSlideover.vue").then(m => m.default || m)
  },
  {
    name: "settings-partials-AppUserList",
    path: "/settings/partials/AppUserList",
    component: () => import("/usr/src/app/pages/settings/partials/AppUserList.vue").then(m => m.default || m)
  },
  {
    name: "settings-partials-AppUserRoleSelect",
    path: "/settings/partials/AppUserRoleSelect",
    component: () => import("/usr/src/app/pages/settings/partials/AppUserRoleSelect.vue").then(m => m.default || m)
  },
  {
    name: "settings-partials-AppUserRoleTag",
    path: "/settings/partials/AppUserRoleTag",
    component: () => import("/usr/src/app/pages/settings/partials/AppUserRoleTag.vue").then(m => m.default || m)
  },
  {
    name: "settings-partials-BillingYearSelect",
    path: "/settings/partials/BillingYearSelect",
    component: () => import("/usr/src/app/pages/settings/partials/BillingYearSelect.vue").then(m => m.default || m)
  },
  {
    name: "settings-partials-ClientAccountsDraggable",
    path: "/settings/partials/ClientAccountsDraggable",
    component: () => import("/usr/src/app/pages/settings/partials/ClientAccountsDraggable.vue").then(m => m.default || m)
  },
  {
    name: "settings-partials-ClientAccountsWrapper",
    path: "/settings/partials/ClientAccountsWrapper",
    component: () => import("/usr/src/app/pages/settings/partials/ClientAccountsWrapper.vue").then(m => m.default || m)
  },
  {
    name: "settings-partials-ClientCreateSlideover",
    path: "/settings/partials/ClientCreateSlideover",
    component: () => import("/usr/src/app/pages/settings/partials/ClientCreateSlideover.vue").then(m => m.default || m)
  },
  {
    name: "settings-partials-ClientDetailPlatformTabs",
    path: "/settings/partials/ClientDetailPlatformTabs",
    component: () => import("/usr/src/app/pages/settings/partials/ClientDetailPlatformTabs.vue").then(m => m.default || m)
  },
  {
    name: "settings-partials-ClientDetailSlideover",
    path: "/settings/partials/ClientDetailSlideover",
    component: () => import("/usr/src/app/pages/settings/partials/ClientDetailSlideover.vue").then(m => m.default || m)
  },
  {
    name: "settings-partials-ClientEditSlideover",
    path: "/settings/partials/ClientEditSlideover",
    component: () => import("/usr/src/app/pages/settings/partials/ClientEditSlideover.vue").then(m => m.default || m)
  },
  {
    name: "settings-partials-ClientForm",
    path: "/settings/partials/ClientForm",
    component: () => import("/usr/src/app/pages/settings/partials/ClientForm.vue").then(m => m.default || m)
  },
  {
    name: "settings-partials-ClientLayoutSelect",
    path: "/settings/partials/ClientLayoutSelect",
    component: () => import("/usr/src/app/pages/settings/partials/ClientLayoutSelect.vue").then(m => m.default || m)
  },
  {
    name: "settings-partials-ClientList",
    path: "/settings/partials/ClientList",
    component: () => import("/usr/src/app/pages/settings/partials/ClientList.vue").then(m => m.default || m)
  },
  {
    name: "settings-partials-ConnectionErrorAlert",
    path: "/settings/partials/ConnectionErrorAlert",
    component: () => import("/usr/src/app/pages/settings/partials/ConnectionErrorAlert.vue").then(m => m.default || m)
  },
  {
    name: "settings-partials-ConnectionReauthenticationAlert",
    path: "/settings/partials/ConnectionReauthenticationAlert",
    component: () => import("/usr/src/app/pages/settings/partials/ConnectionReauthenticationAlert.vue").then(m => m.default || m)
  },
  {
    name: "settings-partials-InvoicesList",
    path: "/settings/partials/InvoicesList",
    component: () => import("/usr/src/app/pages/settings/partials/InvoicesList.vue").then(m => m.default || m)
  },
  {
    name: "settings-partials-NoClientsPlaceholder",
    path: "/settings/partials/NoClientsPlaceholder",
    component: () => import("/usr/src/app/pages/settings/partials/NoClientsPlaceholder.vue").then(m => m.default || m)
  },
  {
    name: "settings-partials-NotificationToggle",
    path: "/settings/partials/NotificationToggle",
    component: () => import("/usr/src/app/pages/settings/partials/NotificationToggle.vue").then(m => m.default || m)
  },
  {
    name: "settings-partials-PaymentMethodIcon",
    path: "/settings/partials/PaymentMethodIcon",
    component: () => import("/usr/src/app/pages/settings/partials/PaymentMethodIcon.vue").then(m => m.default || m)
  },
  {
    name: "settings-partials-SelectAccountsModal",
    path: "/settings/partials/SelectAccountsModal",
    component: () => import("/usr/src/app/pages/settings/partials/SelectAccountsModal.vue").then(m => m.default || m)
  },
  {
    name: "settings-partials-SettingsLayout",
    path: "/settings/partials/SettingsLayout",
    component: () => import("/usr/src/app/pages/settings/partials/SettingsLayout.vue").then(m => m.default || m)
  },
  {
    name: "settings-partials-SettingsSection",
    path: "/settings/partials/SettingsSection",
    component: () => import("/usr/src/app/pages/settings/partials/SettingsSection.vue").then(m => m.default || m)
  },
  {
    name: "settings-partials-TopCreativeDigestForm",
    path: "/settings/partials/TopCreativeDigestForm",
    component: () => import("/usr/src/app/pages/settings/partials/TopCreativeDigestForm.vue").then(m => m.default || m)
  },
  {
    name: "settings-partials-UnassignedAccountsWrapper",
    path: "/settings/partials/UnassignedAccountsWrapper",
    component: () => import("/usr/src/app/pages/settings/partials/UnassignedAccountsWrapper.vue").then(m => m.default || m)
  },
  {
    name: "settings-profile",
    path: "/settings/profile",
    component: () => import("/usr/src/app/pages/settings/profile.vue").then(m => m.default || m)
  },
  {
    name: "settings-team",
    path: "/settings/team",
    component: () => import("/usr/src/app/pages/settings/team.vue").then(m => m.default || m)
  },
  {
    name: "settings-workspace",
    path: "/settings/workspace",
    component: () => import("/usr/src/app/pages/settings/workspace.vue").then(m => m.default || m)
  },
  {
    name: "signup",
    path: "/signup",
    meta: indexZh0bVlLDf6Meta || {},
    component: () => import("/usr/src/app/pages/signup/index.vue").then(m => m.default || m)
  },
  {
    name: "signup-partials-SignUpForm",
    path: "/signup/partials/SignUpForm",
    component: () => import("/usr/src/app/pages/signup/partials/SignUpForm.vue").then(m => m.default || m)
  },
  {
    name: "tagging-labeled",
    path: "/tagging/labeled",
    meta: labeledROYL86MZ8sMeta || {},
    component: () => import("/usr/src/app/pages/tagging/labeled.vue").then(m => m.default || m)
  },
  {
    name: "tagging-partials-BulkActions",
    path: "/tagging/partials/BulkActions",
    component: () => import("/usr/src/app/pages/tagging/partials/BulkActions.vue").then(m => m.default || m)
  },
  {
    name: "tagging-partials-NoTagsPlaceholder",
    path: "/tagging/partials/NoTagsPlaceholder",
    component: () => import("/usr/src/app/pages/tagging/partials/NoTagsPlaceholder.vue").then(m => m.default || m)
  },
  {
    name: "tagging-partials-SelectTags",
    path: "/tagging/partials/SelectTags",
    component: () => import("/usr/src/app/pages/tagging/partials/SelectTags.vue").then(m => m.default || m)
  },
  {
    name: "tagging-partials-SelectTagsModal",
    path: "/tagging/partials/SelectTagsModal",
    component: () => import("/usr/src/app/pages/tagging/partials/SelectTagsModal.vue").then(m => m.default || m)
  },
  {
    name: "tagging-partials-TaggingLayout",
    path: "/tagging/partials/TaggingLayout",
    component: () => import("/usr/src/app/pages/tagging/partials/TaggingLayout.vue").then(m => m.default || m)
  },
  {
    name: "tagging-partials-TagList",
    path: "/tagging/partials/TagList",
    component: () => import("/usr/src/app/pages/tagging/partials/TagList.vue").then(m => m.default || m)
  },
  {
    name: "tagging-partials-TagSingleAd",
    path: "/tagging/partials/TagSingleAd",
    component: () => import("/usr/src/app/pages/tagging/partials/TagSingleAd.vue").then(m => m.default || m)
  },
  {
    name: "tagging-partials-ToggleUnlabeled",
    path: "/tagging/partials/ToggleUnlabeled",
    component: () => import("/usr/src/app/pages/tagging/partials/ToggleUnlabeled.vue").then(m => m.default || m)
  },
  {
    name: "tagging-unlabeled",
    path: "/tagging/unlabeled",
    meta: unlabeledIdau8Hx5VyMeta || {},
    component: () => import("/usr/src/app/pages/tagging/unlabeled.vue").then(m => m.default || m)
  },
  {
    name: "testing-log-cockpit",
    path: "/testing-log/cockpit",
    meta: indexBqPEVVpQYaMeta || {},
    component: () => import("/usr/src/app/pages/testing-log/cockpit/index.vue").then(m => m.default || m)
  },
  {
    name: "testing-log-detail-uuid",
    path: "/testing-log/detail/:uuid()",
    component: () => import("/usr/src/app/pages/testing-log/detail/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "testing-log",
    path: "/testing-log",
    meta: indextuLQUcl61ZMeta || {},
    component: () => import("/usr/src/app/pages/testing-log/index.vue").then(m => m.default || m)
  },
  {
    name: "testing-log-partials-AnalyzingNamingConventionPlaceholder",
    path: "/testing-log/partials/AnalyzingNamingConventionPlaceholder",
    component: () => import("/usr/src/app/pages/testing-log/partials/AnalyzingNamingConventionPlaceholder.vue").then(m => m.default || m)
  },
  {
    name: "testing-log-partials-Breadcrumbs",
    path: "/testing-log/partials/Breadcrumbs",
    component: () => import("/usr/src/app/pages/testing-log/partials/Breadcrumbs.vue").then(m => m.default || m)
  },
  {
    name: "testing-log-partials-CopyNamingConvention",
    path: "/testing-log/partials/CopyNamingConvention",
    component: () => import("/usr/src/app/pages/testing-log/partials/CopyNamingConvention.vue").then(m => m.default || m)
  },
  {
    name: "testing-log-partials-DuplicateTestingLogReportModal",
    path: "/testing-log/partials/DuplicateTestingLogReportModal",
    component: () => import("/usr/src/app/pages/testing-log/partials/DuplicateTestingLogReportModal.vue").then(m => m.default || m)
  },
  {
    name: "testing-log-partials-NamingConventionBuilder",
    path: "/testing-log/partials/NamingConventionBuilder",
    component: () => import("/usr/src/app/pages/testing-log/partials/NamingConventionBuilder.vue").then(m => m.default || m)
  },
  {
    name: "testing-log-partials-Question",
    path: "/testing-log/partials/Question",
    component: () => import("/usr/src/app/pages/testing-log/partials/Question.vue").then(m => m.default || m)
  },
  {
    name: "testing-log-partials-TestingLog",
    path: "/testing-log/partials/TestingLog",
    component: () => import("/usr/src/app/pages/testing-log/partials/TestingLog.vue").then(m => m.default || m)
  },
  {
    name: "testing-log-partials-TestingLogAskForNamingConvention",
    path: "/testing-log/partials/TestingLogAskForNamingConvention",
    component: () => import("/usr/src/app/pages/testing-log/partials/TestingLogAskForNamingConvention.vue").then(m => m.default || m)
  },
  {
    name: "testing-log-partials-TestingLogCockpitHeader",
    path: "/testing-log/partials/TestingLogCockpitHeader",
    component: () => import("/usr/src/app/pages/testing-log/partials/TestingLogCockpitHeader.vue").then(m => m.default || m)
  },
  {
    name: "testing-log-partials-TestingLogColumnCard",
    path: "/testing-log/partials/TestingLogColumnCard",
    component: () => import("/usr/src/app/pages/testing-log/partials/TestingLogColumnCard.vue").then(m => m.default || m)
  },
  {
    name: "testing-log-partials-TestingLogColumnGrid",
    path: "/testing-log/partials/TestingLogColumnGrid",
    component: () => import("/usr/src/app/pages/testing-log/partials/TestingLogColumnGrid.vue").then(m => m.default || m)
  },
  {
    name: "testing-log-partials-TestingLogGroupDetailSlideover",
    path: "/testing-log/partials/TestingLogGroupDetailSlideover",
    component: () => import("/usr/src/app/pages/testing-log/partials/TestingLogGroupDetailSlideover.vue").then(m => m.default || m)
  },
  {
    name: "testing-log-partials-TestingLogHeader",
    path: "/testing-log/partials/TestingLogHeader",
    component: () => import("/usr/src/app/pages/testing-log/partials/TestingLogHeader.vue").then(m => m.default || m)
  },
  {
    name: "testing-log-partials-TestingLogPrimaryColumnSelect",
    path: "/testing-log/partials/TestingLogPrimaryColumnSelect",
    component: () => import("/usr/src/app/pages/testing-log/partials/TestingLogPrimaryColumnSelect.vue").then(m => m.default || m)
  },
  {
    name: "testing-log-partials-TestingLogReport",
    path: "/testing-log/partials/TestingLogReport",
    component: () => import("/usr/src/app/pages/testing-log/partials/TestingLogReport.vue").then(m => m.default || m)
  },
  {
    name: "testing-log-partials-TestingLogSecondaryColumnsSelect",
    path: "/testing-log/partials/TestingLogSecondaryColumnsSelect",
    component: () => import("/usr/src/app/pages/testing-log/partials/TestingLogSecondaryColumnsSelect.vue").then(m => m.default || m)
  },
  {
    name: "testing-log-partials-TestingLogTable",
    path: "/testing-log/partials/TestingLogTable",
    component: () => import("/usr/src/app/pages/testing-log/partials/TestingLogTable.vue").then(m => m.default || m)
  },
  {
    name: "testing-log-partials-TestingLogTabs",
    path: "/testing-log/partials/TestingLogTabs",
    component: () => import("/usr/src/app/pages/testing-log/partials/TestingLogTabs.vue").then(m => m.default || m)
  },
  {
    name: "testing-log-partials-TestingLogToolbar",
    path: "/testing-log/partials/TestingLogToolbar",
    component: () => import("/usr/src/app/pages/testing-log/partials/TestingLogToolbar.vue").then(m => m.default || m)
  },
  {
    name: "testing-log-partials-TestingLogTutorialModal",
    path: "/testing-log/partials/TestingLogTutorialModal",
    component: () => import("/usr/src/app/pages/testing-log/partials/TestingLogTutorialModal.vue").then(m => m.default || m)
  },
  {
    name: "testing-log-partials-TestingLogUpsertCategorySlideover",
    path: "/testing-log/partials/TestingLogUpsertCategorySlideover",
    component: () => import("/usr/src/app/pages/testing-log/partials/TestingLogUpsertCategorySlideover.vue").then(m => m.default || m)
  },
  {
    name: "testing-log-partials-UpsertNamingConventionCategoryModal",
    path: "/testing-log/partials/UpsertNamingConventionCategoryModal",
    component: () => import("/usr/src/app/pages/testing-log/partials/UpsertNamingConventionCategoryModal.vue").then(m => m.default || m)
  },
  {
    name: "testing-log-setup-ai-naming-convention",
    path: "/testing-log/setup/ai-naming-convention",
    meta: ai_45naming_45conventionGoTbqom2gTMeta || {},
    component: () => import("/usr/src/app/pages/testing-log/setup/ai-naming-convention.vue").then(m => m.default || m)
  },
  {
    name: "testing-log-setup-ask-automatic-setup",
    path: "/testing-log/setup/ask-automatic-setup",
    component: () => import("/usr/src/app/pages/testing-log/setup/ask-automatic-setup.vue").then(m => m.default || m)
  },
  {
    name: "testing-log-setup-naming-convention-builder",
    path: "/testing-log/setup/naming-convention-builder",
    meta: naming_45convention_45builderORdFik8lL9Meta || {},
    component: () => import("/usr/src/app/pages/testing-log/setup/naming-convention-builder.vue").then(m => m.default || m)
  },
  {
    name: "testing-log-setup-start",
    path: "/testing-log/setup/start",
    component: () => import("/usr/src/app/pages/testing-log/setup/start.vue").then(m => m.default || m)
  },
  {
    name: "testing-log-setup-tagging",
    path: "/testing-log/setup/tagging",
    component: () => import("/usr/src/app/pages/testing-log/setup/tagging.vue").then(m => m.default || m)
  },
  {
    name: "testing-log-setup-tutorial",
    path: "/testing-log/setup/tutorial",
    component: () => import("/usr/src/app/pages/testing-log/setup/tutorial.vue").then(m => m.default || m)
  },
  {
    name: "testing-log-share-uuid",
    path: "/testing-log/share/:uuid()",
    meta: _91uuid_93nqPTQaj4owMeta || {},
    component: () => import("/usr/src/app/pages/testing-log/share/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "testing-log-snapshot-uuid",
    path: "/testing-log/snapshot/:uuid()",
    meta: _91uuid_93YQgVPeBVskMeta || {},
    component: () => import("/usr/src/app/pages/testing-log/snapshot/[uuid].vue").then(m => m.default || m)
  }
]