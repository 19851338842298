export const DOMAIN = "datads.io";
export const LOCAL_BASE_URL = "http://localhost:3000";

export const AUTH0_CLIENT_ID_LOCAL = "AIPmhBKJq7Oe5WL7mPFWmPV0ugVKc6az";
export const AUTH0_DOMAIN_LOCAL = "datads-dev.eu.auth0.com";
export const AUTH0_ISSUER_LOCAL = "https://datads-dev.eu.auth0.com";
export const AUTH0_AUDIENCE_LOCAL = "https://auth.datads.io/";

export const AUTH0_CLIENT_ID_QA = "0Cz2CR9ohUcAhfKUcR9gcrjOH8rNieqT";
export const AUTH0_DOMAIN_QA = "datads-qa.eu.auth0.com";
export const AUTH0_ISSUER_QA = "https://datads-qa.eu.auth0.com";
export const AUTH0_AUDIENCE_QA = "https://datads-qa.eu.auth0.com/api/v2/";

export const AUTH0_CLIENT_ID_PROD = "N2mmUcZFjQz260P5h4GPg1p67Z03vzcq";
export const AUTH0_DOMAIN_PROD = "datads.eu.auth0.com";
export const AUTH0_ISSUER_PROD = "https://datads.eu.auth0.com";
export const AUTH0_AUDIENCE_PROD = "https://datads.eu.auth0.com/api/v2/";

export const MAX_PAGE_SIZE = 100;
export const DEFAULT_PAGE_SIZE = 4;

export const INSIGHTS_DEVIATION_EXPLANATION =
  "Percent deviation is computed using the prior period as a basis. For instance, when you select the last 7 days, the data is contrasted with the previous 7-day interval. A question mark is displayed if the value for the preceding period is either 0 or undefined (such as when no events were recorded during that time), making it impossible to calculate the percentage";

export const FLAGSMITH_ENVIRONMENT_ID_LOCAL = "G4d2mNacwk6AyX5t8cPpAo";
export const FLAGSMITH_ENVIRONMENT_ID_QA = "GacUiGdTWRgKybGXKP3zKV";
export const FLAGSMITH_ENVIRONMENT_ID_PROD = "cB2UfoMeGUx5LvbyUb3yra";

export const DASHBOARD_INTRO_VIDEO = "zcEdoOhsBQw";
export const CREATIVE_COMPARISON_INTRO_VIDEO = "p757OZurv_k";
export const CREATIVE_REPORTING_INTRO_VIDEO = "3Q0mEqTXqoE";
export const CUSTOM_REPORTS_INTRO_VIDEO = "18MlDywIlzw";
export const INSIGHTS_INTRO_VIDEO = "4Eh4ISj7GGo";
export const CREATE_CONNECTION_VIDEO = "NjBkvQ51Yww";
export const CREATE_CLIENT_VIDEO = "o0mkC4WoyrA";
export const CREATIVE_REPORTING_ONBOARDING_VIDEO = "hEM8ltFatOk";
export const CREATIVE_COMPARISON_ONBOARDING_VIDEO = "ZkXEssSsT1Y";
export const NAMING_CONVENTION_VIDEO = "MdvuAiLCtFw";

export const EDIT_MAPPING_GIF = "/edit-mapping.gif";

export const HELP_PAGE = "https://www.datads.io/ressourcen";
export const NAMING_CONVENTION_ARTICLE =
  "https://www.datads.io/ressourcen/warum-eine-saubere-naming-convention-so-wichtig-ist-intro-zu-der-datads-naming-convention";
export const MEDIA_URL_PICKER_CHROME_EXTENSION =
  "https://chrome.google.com/webstore/detail/ad-library-media-url-pick/bfklgdbpeojhdbpnbkehomjlahaeijik";
export const NAMING_CONVENTION_ARTICLE_IFRAME =
  "https://www.datads.io/ressourcen/warum-eine-saubere-naming-convention-so-wichtig-ist-intro-zu-der-datads-naming-convention";

export const META_UTM_STRUCTURE =
  "utm_source=meta&utm_medium=cpm&utm_campaign={{campaign.name}}&utm_content={{ad.id}}&utm_placement={{placement}}";
export const TIKTOK_UTM_STRUCTURE =
  "?utm_source=tiktok&utm_medium=_cpm&utm_campaign=__CAMPAIGN_NAME__&utm_content=__CID__";

export const TABLE_PAGE_SIZE = 4;
export const CAROUSEL_PAGE_SIZE = 4;
export const PINTEREST_VIEW_PAGE_SIZE = 4;
// Must be multiples of 4
export const TABLE_PAGE_SIZE_OPTIONS = [4, 8, 16];

export const ACCUMULATED_TABLE_ROW_ID = -1;
export const ACCUMULATED_TABLE_ROW_NAME = "Accumulated";

export const IMG_ENDINGS = /jpeg|jpg|png|gif/;
export const VIDEO_ENDINGS = /webm|mp4|mkv|psd|pdf|ai|raw/;
export const MEDIA_ENDINGS = /jpeg|jpg|png|gif|webm|mp4|mkv|psd|pdf|ai|raw/;

export const FB_ADS_LIBRARY = "https://www.facebook.com/ads/library";
export const FACEBOOK_ADS_MANAGER_LINK =
  "https://business.facebook.com/adsmanager/manage/ads?act=$actid&selected_campaign_ids=$adcampaignid&selected_adset_ids=$adsetid&selected_ad_ids=$adid";
export const FACEBOOK_ADS_MANAGER_CREATIVE_LINK =
  "https://business.facebook.com/adsmanager/manage/ads/edit?act=$actid&selected_adset_ids=$adsetid&selected_ad_ids=$adid";

export const TIKTOK_ADS_LIBRARY = "https://library.tiktok.com/ads?region=DE";
export const TIKTOK_ADS_MANAGER_LINK =
  "https://ads.tiktok.com/i18n/perf/adgroup?aadvid=$actid";
export const TIKTOK_ADS_MANAGER_CREATIVE_LINK =
  "https://ads.tiktok.com/i18n/perf/creative?aadvid=$actid&keyword=$adid&search_type=3";

export const STRIPE_PUBLISHABLE_KEY_DEV =
  "pk_test_51MaKckESqG48hppQQN8wBfeX7EsZQJFT2Rad7EUIE8DgxD09olgG7O621NNSO530cZ4tPsxFRJKvbZm3NSAhA9pf002duPibsT";
export const STRIPE_PUBLISHABLE_KEY_PROD =
  "pk_live_51MaKckESqG48hppQl7kTlvWyj8Xztraok8pdBGSgpH9b11cDbSPLNt0hJbIuKgIUwqU2e8lihaTXveygx1bgil1e00hj3odnBI";

export const BREAKDOWN_COLUMN_NULL_KEY = "Unknown";

export const HAS_MAINTENANCE = false;

export const ISSUE_TYPEFORM = "https://c6mpvc2ps0w.typeform.com/to/UFfZFKZu";
export const SIGN_UP_URL = "https://app.datads.io/signup";
export const POST_SIGN_UP_URL = "https://datads.io/signup-success";

export const GOOGLE_TAG_MGR_HEAD = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-M4L6TP7');`;
export const GOOGLE_TAG_MGR_BODY = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-M4L6TP7"
height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

export const COLORS = [
  "red",
  "blue",
  "green",
  "yellow",
  "purple",
  "pink",
  "indigo",
  "gray",
  "orange",
  "teal",
];
